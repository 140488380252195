import { useState, useEffect } from "react";
import TextDisplay from "../components/TextDisplay.js";

function FAQs(props) {
    let { article } = props;
    let { loading } = props;

    return (
    <>
        <div className="container">
            <br/>
            <h2>FAQs</h2>
            <p><b>What is this?</b> An AI-powered Illuminator of Russell Conjugations in text! You can learn more on
            the <a href="/about">About Page</a>.</p>

            <p><b>What are Russell Conjugations?</b> At their core, Russell Conjugations are factual synonyms with the opposite emotional meaning.
            It's a form of fact-independent bias that allows the user to place emotional judgments on things without argumentation,
            and without necessarily lying about the facts of a situation. As opposied to a fact-checker, this tool doesn't (or at least,
            shouldn't) comment on factual claims. Instead, it will reveal and give you alternatives to the ways certain claims are
            presented.</p>

            <p><b>Is this a fact-checker?</b> No. This tool should not highlight factually incorrect statements or misrepresentations of
            facts. It should only highlight words and phrases for which synonyms with different emotional content exist.</p>

            <p><b>Why can't I use it?</b> Because it's not finished yet. While it's generally capable of providing Russell Conjugations for
            phrases in given text, it is quite prone to mistakes: misidentifying emotion, providing alternatives to phrases with
            completely different factual meanings, and much more. That's why, for now, only specific individuals have access to the tool,
            and can only share certain examples. A tool meant to raise awareness for Russell Conjugations is no good if it spits
            back things that aren't Russell Conjugations. That would have the opposite effect, confusing and devaluing what a Russell
            Conjugation is. <br/> &emsp; There are also practical reasons why I can't make this completely public yet. I'm just one person,
            and I'm not ready to deal with copyright claims if people paste copyrighted texts here and start sharing them all over the place.
            I would also likely be unable to support the tool financially. A lot of thought will have to go into how this tool is finally
            released to ensure that it's useful, legal, and won't drain my bank account.</p>

            <p><b>Can I use it?</b> Honestly, probably not. A good deal of trust is required for that at this point. If you want to start
            a discussion, fill out the <a href="https://forms.gle/Qm7xiti8kMx7QcQr7">Google Form</a>, and we may be able to set something
            up as the tool progresses. Otherwise, the best way to support this tool's development would be to share
            the <a href="/Examples">public examples</a> with others, or donating to me with the button on the bottom of this page.
            Hosting this website, finetuning models, and querying the model all cost money, and I'd like to hope that all my efforts can
            at least break even financially.</p>
        </div>
        <br />
        <hr />
        <TextDisplay className="article" text={article} loading={loading} />
    </>
    );
}

export default FAQs;