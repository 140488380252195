import { useState, useEffect } from "react";
import TextDisplay from "../components/TextDisplay.js";

function Home(props) {
    let { article } = props;
    let { loading } = props;

    return (
        <>
        <div className="container">
            <br />
            <p>Learn more:</p>
            <p>
                &ensp;<i>Wikipedia: <a href="https://en.wikipedia.org/wiki/Emotive_conjugation">Emotive conjugation</a></i><br/>
                &ensp;<i><a href="https://www.edge.org/response-detail/27181">Eric Weinstein's 2017 response for Edge</a></i>
                <br /><br /><br />
            </p>
        </div>
        <hr />
        <TextDisplay className="article" text={article} loading={loading} />
      </>
    );
}

export default Home;