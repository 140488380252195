
function NavBar() {
    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light" style={{paddingBottom: `0`}}>
                <div className="container flex-sm-nowrap">
                    <a className="navbar-brand" href="/"><h1 style={{fontSize: `2rem`}}>Russell Conjugation Illuminator</h1></a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <div className="navbar-nav">
                            <a className="nav-link" href="/about">About</a>
                            <a className="nav-link" href="/FAQs">FAQs</a>
                            <a className="nav-link" href="/examples">Examples</a>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default NavBar;