import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import TextDisplay from "./TextDisplay.js";

const ConjVerify = (props) => {
  const [loading, setLoading] = useState(true);
  const [good, setGood] = useState(false);
  let { conjId } = useParams();
  let { exists } = props;
  let { data } = props;
  let { onGet } = props;

  useEffect(() => {
    // Don't make server request if already received
    if(conjId === exists && data && data.Text.S === conjId) {
        onGet(JSON.parse(data.Conjugations.S), data.Plaintext.S);
        setGood(true);
        setLoading(false);
        return;
    }

    if (!/^[0-9a-f]{32}$/.test(conjId) && conjId !== "TrumpPoliticalPrisoner" && conjId !== "BidenDescribesMAGA"
      && conjId !== "UnbiasedJesusReporting") {
      setLoading(false);
      return;
    }

    const makeReq = async (conjId, event) => {
      try {
        const response = await fetch(
          'https://llkuy39n29.execute-api.us-east-1.amazonaws.com/prod/api/oldConj',
          //"http://localhost:5000/api/oldConj",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              conjId,
            }),
          }
        );

        const tempResponseData = await response;

        if (response.status !== 200) {
            setLoading(false);
          return response.status;
        }

        const responseData = await tempResponseData.json();

        onGet(JSON.parse(responseData.conj.Conjugations.S), responseData.conj.Plaintext.S);
        //console.log(responseData.conj.Conjugations.S);
        //console.log(responseData.conj.Plaintext.S);
        setGood(true);
        setLoading(false);
        return;

      } catch (err) {
        setLoading(false);
        console.log(err);
        return "An error occured";
      }
    };

    makeReq(conjId);
  }, []);

  return loading ? (
    <p className="feedback">Loading conjugations...</p>
  ) : !good ? (
    <p className="feedback errorText">Invalid URL! Try something else</p>
  ) : (
      <TextDisplay
        className="article"
        text={props.text}
        loading={props.loading}
        conjId={conjId}
      />
  );
};

export default ConjVerify;
