import { useState, useEffect } from "react";
import TextDisplay from "../components/TextDisplay.js";

function About(props) {
    let { article } = props;
    let { loading } = props;

    return (
        <>
            <div className="container">
                <br/>
                <h2>About</h2>
                
                <p>A Russell Conjugation is simply a factual equivalent for a word/phrase with different emotional meaning.</p>
                <p>This website is part of an ongoing project of mine to use AI to identify and highlight fact-independent bias in text.
                    This website uses a finetuned ChatGPT-4o model trained on examples of political text, relatively biased/unbiased news,
                    social media posts, encyclopedia entries, and other texts to highlight examples of Russell Conjugations in given text
                    and provide their emotionally different alternatives.
                </p>
                <p>It won't always spit back proper Russell Conjugations, and it still has trouble accurately gauging relative tone and
                    providing alternatives with notable emotional differences. But after over a year of creating training sets and tinkering
                    with definitions, it's good enough for a trial run, with specific examples posted to the public to raise awareness for this
                    interesting feature of modern political and social life.
                </p>
                <p>For answers to common questions about the tool, see the <a href="/FAQs">FAQs page</a>.</p>
                <p>For further information about Russell Conjugations, see the links below: <br/>
                &ensp;<i>Wikipedia: <a href="https://en.wikipedia.org/wiki/Emotive_conjugation">Emotive conjugation</a></i><br/>
                &ensp;<i><a href="https://www.edge.org/response-detail/27181">Eric Weinstein's 2017 response for Edge</a></i></p>
            </div>
            <br />
            <hr />
            <TextDisplay className="article" text={article} loading={loading} />
        </>
    );
}

export default About;